// snackbar
export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";

// message
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

// command pallete types
export const OPEN_COMMAND_PALLETE = "OPEN_COMMAND_PALLETE";
export const CLOSE_COMMAND_PALLETE = "CLOSE_COMMAND_PALLETE";

//cart types
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const CLEAN_CART = "CLEAN_CART";

//product types
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE";
export const CLEAN_PRODUCTS = "CLEAN_PRODUCTS";

// fetch viewed products from local storage
export const ADD_NEW_VIEWED_PRODUCT_SUCCESS = "ADD_NEW_VIEWED_PRODUCT_SUCCESS";
export const FETCH_VIEWED_PRODUCTS_SUCCESS = "FETCH_VIEWED_PRODUCTS_SUCCESS";
export const FETCH_VIEWED_PRODUCTS_FAILURE = "FETCH_VIEWED_PRODUCTS_FAILURE";
export const CLEAN_VIEWED_PRODUCTS = "CLEAN_VIEWED_PRODUCTS";
